import React from "react";
import "./Header.css";

import logo from "../../photos/sandviglogo.png";

const Header = ({ isVisible, toggleOverlay }) => {
  return (
    <>
      <section className="top-nav">
        <a href="https://www.sandvig.info" target="_blank" rel="noreferrer">
          <img className="header-logo" src={logo} alt="logo" />
        </a>
        <input id="menu-toggle" type="checkbox" />
        <label className="menu-button-container" htmlFor="menu-toggle">
          <div className="menu-button"></div>
        </label>
        <ul className="menu">
          <li onClick={() => toggleOverlay("plakat")}>Festivalplakat 2024</li>
          <li onClick={() => toggleOverlay("sponsor")}>Sponsorer</li>
        </ul>
      </section>
    </>
  );
};

export default Header;
