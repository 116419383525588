import React from "react";
import "./Modal.css";

const Modal = ({ isVisible, toggleOverlay, children }) => {
  return (
    <div className={`content-${isVisible ? "visible" : "hidden"}`}>
      <button onClick={toggleOverlay} aria-label="close" className="x">
        ❌
      </button>
      {children}
    </div>
  );
};

export default Modal;
