import React from "react";
import "./Overlay.css";

const Overlay = ({ isVisible, toggleOverlay, children }) => {
  return (
    <div
      className={`overlay-${isVisible ? "visible" : "hidden"}`}
      //   onClick={toggleOverlay}
    >
      {/* <div className="content">
        <button onClick={toggleOverlay} aria-label="close" className="x">
          ❌
        </button>
        {children}
      </div> */}
    </div>
  );
};

export default Overlay;
