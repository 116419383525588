import React from "react";
import "./Sponsors.css";
import sponsorContent from "../../../content/sponsorContent.json";
import logo1 from "../../../photos/logo_sandloppen.jpg";
import logo2 from "../../../photos/logo_bagsiden.png";
import logo3 from "../../../photos/logo_BornholmDistillery.jpg";
import logo4 from "../../../photos/logo_BB.png";
import logo5 from "../../../photos/logo_Bornholmnyt-dk.png";
import logo6 from "../../../photos/logo_brk.jpg";
import logo7 from "../../../photos/logo_DanhostelSandvig.png";
import logo8 from "../../../photos/logo_denne-uges-bornholm.jpg";
import logo9 from "../../../photos/logo_Dideriks.png";
import logo10 from "../../../photos/logo_EDC.jpg";
import logo11 from "../../../photos/logo_Ellas.jpg";
import logo12 from "../../../photos/Logo_Falafel.jpg";
import logo13 from "../../../photos/logo_foodtruck_bornholm.png";
import logo14 from "../../../photos/logo_hammerfyr.jpg";
import logo15 from "../../../photos/Logo_Baadfart.jpg";
import logo16 from "../../../photos/logo_HammerhavnKiosk.jpg";
import logo17 from "../../../photos/logo_Hammerknuden.jpg";
import logo18 from "../../../photos/logo_HJ_Byg.jpg";
import logo19 from "../../../photos/logo_Hammersø.png";
import logo20 from "../../../photos/logo_pepita.jpg";
import logo21 from "../../../photos/logo_husflid.jpg";
import logo22 from "../../../photos/logo_nordbornholms_rogeri.jpg";
import logo23 from "../../../photos/Logo_Nordlandet.jpg";
import logo24 from "../../../photos/logo_nordvig.jpg";
import logo25 from "../../../photos/logo_PensionHolmegaard.png";
import logo26 from "../../../photos/logo_PensionLangebjerg.jpg";
import logo27 from "../../../photos/logo_Pilen.jpg";
import logo28 from "../../../photos/logo_Stjernan.jpg";
import logo29 from "../../../photos/Logo_SandvigFamiliecamping.png";
import logo30 from "../../../photos/Logo_SandvigHavn.jpg";
import logo31 from "../../../photos/logo_sandvig_minigolf.jpg";
import logo32 from "../../../photos/logo_Kalas.jpg";
import logo33 from "../../../photos/logo_scb.jpg";
import logo34 from "../../../photos/logo_sbfonden.png";
import logo35 from "../../../photos/logo_kokkenberg.jpg";
import logo36 from "../../../photos/logo_Stark.jpg";
import logo37 from "../../../photos/logo_strandgaarden.png";
import logo38 from "../../../photos/logo_AllingeBrugs.jpg";
import logo39 from "../../../photos/logo_TejnIF.png";
import logo40 from "../../../photos/logo_vvs.jpg";
import logo41 from "../../../photos/logo_WF.jpg";

const Sponsors = () => {
  const logolist = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
    logo25,
    logo26,
    logo27,
    logo28,
    logo29,
    logo30,
    logo31,
    logo32,
    logo33,
    logo34,
    logo35,
    logo36,
    logo37,
    logo38,
    logo39,
    logo40,
    logo41,
  ];
  return (
    <div className="sponsor-modal">
      <h2 className="sponsor-modal-title">Tak til alle vores sponsorer</h2>
      <div className="sponsor-modal-content">
        {sponsorContent.map((content, index) => (
          <div key={index} className="sponsor-modal-content-item">
            <a
              href={content.link}
              target="_blank"
              rel="noreferrer"
              className="sponsor-link-text"
            >
              {content.name}
            </a>
            <a href={content.link} target="_blank" rel="noreferrer">
              <img className="logo" src={logolist[index]} alt={content.name} />
            </a>
            <p className="sponsor-phone-text">{content.phone}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
