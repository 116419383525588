import React from "react";
import "./Page.css";
import Card from "../Card";
import koncertContent from "../../content/koncertContent.json";

import image1 from "../../photos/img_KalleBrandt.jpg";
import image2 from "../../photos/Kroelle.jpg";
import image3 from "../../photos/img_KimLarsenTribute.jpg";
import image4 from "../../photos/IvanMortensen.jpg";
import image5 from "../../photos/img_Valentinos.jpg";
import image6 from "../../photos/img_TheNurchers.jpg";
import image7 from "../../photos/BremerMccoy.jpg";
import image8 from "../../photos/img_jazz.png";
import image9 from "../../photos/img_BjarneKure.jpg";
import image10 from "../../photos/img_Born2Party.jpg";
import image11 from "../../photos/img_TobiasKirk.jpg";
import image12 from "../../photos/img_SandvigScenen.jpg";
import image13 from "../../photos/img_OleChristensen.jpg";
import image14 from "../../photos/img_FrankieS.jpg";
import image15 from "../../photos/image2.jpg";
import image16 from "../../photos/img_HeatMakers.png";
import image17 from "../../photos/image4.jpg";
import image18 from "../../photos/denskaldede.jpg";
import image19 from "../../photos/RasmusogSofus.jpg";
import image20 from "../../photos/img_MellemHimmel.jpg";
import image21 from "../../photos/img_Falkene.jpg";
import image22 from "../../photos/img_BomlesVenner.jpg";
import image23 from "../../photos/image7.jpg";
import image24 from "../../photos/bornpaanord.jpg";
import image25 from "../../photos/jazzFammm.png";
import image26 from "../../photos/img_Lovehandles.jpg";
import image27 from "../../photos/Roegdag.jpg";
import image28 from "../../photos/image8.png";
import image29 from "../../photos/PensionSandbogaard.jpg";
import image30 from "../../photos/AceOfBlues.jpg";
import image31 from "../../photos/Men-of-Constant-Sorrow.jpg";
import image32 from "../../photos/img_Piblana.jpg";
import image33 from "../../photos/SimonS.jpg";
import image34 from "../../photos/MoCS.jpg";
import image35 from "../../photos/img_Cozy.jpg";
import image36 from "../../photos/img_Tfbiyc.jpg";
import image37 from "../../photos/BryanRice.jpg";

const Page = () => {
  // const getImageUrl = (key) => {
  //   const imageObj = imageList.find((image) => Object.keys(image)[0] === key);
  //   return imageObj ? imageObj[key] : null;
  // };

  const imageList = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
  ];

  // const [filterType, setFilterType] = useState("all");

  // const handleFilterChange = (type) => {
  //   filterType === "all"
  //     ? setFilterType("Musikfestival")
  //     : setFilterType("all");
  // };

  // const filteredContent =
  //   filterType === "all"
  //     ? koncertContent
  //     : koncertContent.filter((content) => content.type === "Musikfestival");

  return (
    <>
      {/* <div className="filter-buttons">
        <button onClick={() => handleFilterChange(filterType)}>
          {filterType === "all"
            ? "Se kun Sandvig Musikfestival"
            : "Se alle events"}
        </button>
      </div> */}
      <div className="page-description">
        <p>
          I Sandvig afholdes sommeren igennem koncerter og arrangementer, hvor
          både lokale og besøgende kan nyde alt fra opera over blues og rock til
          popmusik.
          <br />
          Programmet opdateres løbende her.
        </p>
      </div>
      <div className="articles">
        {/* {filteredContent.map((content, index) => ( */}
        {koncertContent.map((content, index) => (
          <Card key={index} content={content} imageUrl={imageList[index]} />
        ))}
      </div>
    </>
  );
};

export default Page;
