import React, { useState, useEffect } from "react";
import "./Card.css";

const Card = ({ content, imageUrl }) => {
  const [cardOpen, setCardOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isPastDate, setIsPastDate] = useState(null);

  useEffect(() => {
    checkIfDateIsPast(content.date.slice(-8, -3));
  });

  const checkIfDateIsPast = (dateStr) => {
    const [day, month] = dateStr.split(".").map(Number);
    const year = new Date().getFullYear();

    const inputDate = new Date(year, month - 1, day); // JS months are 0-based
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate < today) {
      setIsPastDate(true);
    } else {
      setIsPastDate(false);
    }
  };

  const handleClickCard = () => {
    setCardOpen(!cardOpen);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="article">
        <div className="article-wrapper" onClick={handleClickCard}>
          <div className={`label ${isPastDate ? "past-event" : ""}`}>
            {isPastDate !== null && (
              <div>{isPastDate ? "Afholdt" : content.date.slice(0, -3)}</div>
            )}
          </div>
          <figure>
            <img src={imageUrl} alt="Placeholder" />
          </figure>
          <div className="article-body">
            <h2>{content.artist}</h2>
            <p className="time-text">{content.time}</p>
            <p className="location-text">{content.location}</p>
            <p className="type-text">{content.type}</p>
            <div className="read-more">
              Læs mere
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                viewBox="0 0 20 10"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {!cardOpen && (
        <div className="overlay">
          <button
            onClick={handleClickCard}
            aria-label="close"
            className="close-article"
          >
            ❌
          </button>
          <div className="article-open">
            <p className="date-text">{content.date}</p>
            <p className="time-text">{content.time}</p>
            <div className="label-type">{content.type}</div>
            <h3>{content.title}</h3>
            <p className="description-text">{content.description}</p>
            <a
              href={content.address}
              target="_blank"
              rel="noopener noreferrer"
              className="location-text"
            >
              {content.location}
            </a>
            {isLoading && <div className="loader"></div>}
            {!isLoading && content.link !== "" && (
              <div className="link-container">
                <iframe
                  className="link-element"
                  width="560"
                  height="315"
                  frameborder="0"
                  src={content.link}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={() => setIsLoading(false)}
                ></iframe>
              </div>
            )}
            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
