import React from "react";
import "./Poster.css";
import poster from "../../../photos/Musikfestival2024.jpg";
import artist from "../../../photos/leneh.jpg";

const Poster = () => {
  return (
    <div className="modal">
      <h2 className="modal-title">Festivalplakat 2024</h2>
      <div className="modal-content">
        <div className="modal-content-column">
          <img className="poster-img" src={poster} alt="Festivalplakat 2024" />
        </div>
        <div className="modal-content-column">
          <span className="artist-label">Kunstneren bag årets plakat</span>
          <div className="artist-info">
            <p className="artist-name">Lene Haakonsen</p>
            <img
              className="artist-img"
              src={artist}
              alt="Portræt af Lene Haakonsen"
            />
            <div className="artist-bio">
              <span>Om kunstneren</span>
              <p className="quote">
                Jeg har malet siden jeg startede i skolen som 6-årig. Interessen
                er intakt. Jeg har som voksen deltaget i kunsthøjskoleophold, i
                kurser og male-rejser for at kunne og turde mere. Det har været
                en fin "rejse", hvor mange mennesker har givet mig inspiration.
                I dag synes jeg, at Sandvig er det bedste sted til at opsøge
                motiver og skønne, klare farver.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poster;
