import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <ul className="footer-menu">
        {/* <li className="menu__item">
          <p>Kommer snart: Afholdte events</p>
        </li> */}
      </ul>
      <ul className="footer-menu">
        <li className="menu__item">
          <p>Kontakt: fiber_jones@hotmail.com</p>
        </li>
      </ul>
      <ul className="footer-menu">
        <li className="menu__item">
          <a
            className="menu__link"
            rel="noreferrer"
            href="https://www.facebook.com/www.Sandvig.info"
            target="_blank"
          >
            Facebook
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
